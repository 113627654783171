// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDfYY5S2szciQYzvmCL1lftA93Xi4NqyIY",
  authDomain: "artisan-13d10.firebaseapp.com",
  projectId: "artisan-13d10",
  storageBucket: "artisan-13d10.appspot.com",
  messagingSenderId: "34701878403",
  appId: "1:34701878403:web:d3a747d8aa74767ba627b6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
